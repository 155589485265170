import React from 'react';
import PropTypes from 'prop-types';

const GestionTagList = ({ tags }) => {
  let parsedTags = [];

  // Convertir la chaîne JSON en tableau
  try {
    parsedTags = JSON.parse(tags);
  } catch (error) {
    console.error("Erreur lors de la conversion des tags : ", error);
    return null;
  }

  if (!Array.isArray(parsedTags) || parsedTags.length === 0) return null;

  return (
    <div className="tag-container">
      {parsedTags.map((tag, index) => (
        <div
          key={index}
          className="tag"
          style={{
            backgroundColor: `${tag.color}3A`,
            color: tag.color, 
          }}
        >
          {tag.tag}
        </div>
      ))}
    </div>
  );
};

GestionTagList.propTypes = {
  tags: PropTypes.string.isRequired,
};

export default GestionTagList;
