import { useState } from 'react';
import axios from 'axios';


//Contexte
import { useConfig } from '../../contexte/ConfigContext';

/*
Permet à un utilisateur de liker/unliker une publication
*/

const usePostLikeUnlikePublication = (uuidPublication) => {

  const generalConfigSysteme = useConfig();
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [likeUnlike, setlikeUnlike] = useState([]);
  const [responseMessage, setResponseMessage] = useState('');

  const postLikeUnlikePublication = async () => {
    setLoading(true);
    setError(null);

    const token = localStorage.getItem('token'); 

    const data = JSON.stringify({
      uuid_publication: uuidPublication
    });
    

    const config = {
      method: 'post',
      url: `${generalConfigSysteme.apiUrl}/api/utilisateurs/likeUnlikePublication`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json' 
      },
      data: data
    };

    try {
      const response = await axios.request(config);
      setlikeUnlike(response.data);
      setResponseMessage('like/unike réalisé avec succès.');
    } catch (err) {
      setError("Une erreur est survenue lors du like/unlike.");
    } finally {
      setLoading(false);
    }
  };

  return { postLikeUnlikePublication, likeUnlike, loading, error, responseMessage };
};

export default usePostLikeUnlikePublication;
