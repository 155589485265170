import React from 'react';
import { useNavigate } from 'react-router-dom';

const ConnexionInscriptionFenetre = ({ isVisible, onClose }) => {
  
  const navigate = useNavigate();

  if (!isVisible) {
    return null;
  }
    
  //Cas ou l'utilisateur clique en dehors de la fenêtre
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleSignup = () => {
    navigate('/inscription/e-mail'); 
  };

  return (
    <div className="overlay" onClick={handleOverlayClick}>
        <div className="modal">
            <h2 className="sousTitre2">Connectez-vous ou créez un compte</h2>
            <span style={{ display: 'block', height: '8px' }}></span>
            <p className="description">
                Pour liker cette publication, veuillez vous connecter ou créer un compte.
            </p>
            <span style={{ display: 'block', height: '20px' }}></span>
            <div className="button-container">
                <button onClick={handleLogin} className="button login-button">
                    Se connecter
                </button>
                <button onClick={handleSignup} className="button signup-button">
                    S'inscrire
                </button>
            </div>
        </div>
    </div>
  );

};


export default ConnexionInscriptionFenetre;
