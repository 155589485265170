import React from 'react';
import PuffLoader from 'react-spinners/PuffLoader';

const LoadingSpinner = () => {
    return (
        <div className="loaderContainer">
            <PuffLoader color="#4A35AB" loading={true} speedMultiplier={0.8} size={100} aria-label="Loading Spinner"/>
            <span style={{ display: 'block', height: '20px' }}></span>
            <p className="loadingText">Chargement en cours...</p>
        </div>
    );
};

export default LoadingSpinner;
