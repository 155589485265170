import React from 'react';

const Note = ({ moyenne }) => {

  const getClasseAvis = (note) => {
    if (note === null || note === undefined) return 'description_avis_null';
    if (note >= 4.0) return 'description_avis_5';
    if (note >= 3.5) return 'description_avis_4';
    if (note >= 2.5) return 'description_avis_3';
    if (note >= 1.5) return 'description_avis_2';
    return 'description_avis_1';
  };

  return (
    <div className={`note ${getClasseAvis(moyenne)}`}>
      {moyenne !== null && moyenne !== undefined ? `${moyenne.toFixed(1)}` : ''}
    </div>
  );
};


export default Note;
