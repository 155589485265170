import React, { useState } from 'react';

//Assets
import Partage from '../../assets/icone-partage.png';


const ShareLink = ({ generatedShareLink }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');

  // Function to copy the share link to the clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(generatedShareLink).then(
      () => {
        setCopySuccess('Lien copié !');
      },
      (err) => {
        setCopySuccess('Échec de la copie');
        console.error('Could not copy text: ', err);
      }
    );
  };

  const handleLikeClick = () => {
    setModalVisible(true);
    setCopySuccess('');
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  return (
    <div>
      <button onClick={handleLikeClick} className="shareButton">
        <img 
          src={Partage} 
          alt= 'Partage'
          style={{ width: '24px', height: '24px' }}
        />

      </button>

      {isModalVisible && (
        <div className="overlay" onClick={handleOverlayClick}>
          <div className="modal">
            <h2>Partager cette publication</h2>
            <div className="input-container">
              <input
                type="text"
                value={generatedShareLink}
                readOnly
                onClick={copyToClipboard}
                className="input"
              />
              <button onClick={copyToClipboard} className="copy-button">
                Copier
              </button>
            </div>
            <p className="copy-success">{copySuccess}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareLink;
