import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

//Contexte
import { useConfig } from '../../contexte/ConfigContext';

const ConnexionContext = createContext();

export const ConnexionProvider = ({ children }) => {
  
  const generalConfigSysteme = useConfig();
  
  //Fonction pour vérifier si le token n'a pas expiré
  const isTokenExpired = (token) => {
    try {
      const { exp } = jwtDecode(token);
      if (exp < Date.now() / 1000) {
        return true;
      }
      return false;
    } catch (error) {
      console.error('Erreur lors de la vérification du token', error);
      return true;
    }
  };


  // Chargement du jeton depuis le stockage local - Si le token n'est plus valide, il est supprimer
  const [token, setToken] = useState(() => {

    const savedToken = localStorage.getItem('token');
    if (savedToken && !isTokenExpired(savedToken)) {
      return savedToken;
    } else {
      localStorage.removeItem('token');
      return null;
    }

  });

  // Fonction pour se connecter
  const login = async (email, password) => {
    try {
      const response = await axios.post(`${generalConfigSysteme.apiUrl}/api/public/connexion`, {
        email,
        password
      });
  
      const token = response.data.token;
  
      // Stocker uniquement le jeton
      localStorage.setItem('token', token);
      setToken(token);  
  
    } catch (error) {
      throw error;
    }
  };
  
  return (
    <ConnexionContext.Provider value={{ token, login }}>
      {children}
    </ConnexionContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte d'authentification
export const useAuth = () => {
  return useContext(ConnexionContext);
};
