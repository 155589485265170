import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet'; 

//Layout
import PublicationListeVisiteur from '../components/layouts/public/PublicationListeVisiteur' //Visiteur
import PublicationListUtilisateur from '../components/layouts/utilisateur/PublicationListeUtilisateur' //Utilisateur

//Stores
import useGetListePublicationVisiteur from '../components/store/public/post-listePublication'; //Visiteur
import useGetListePublicationUtilisateur from '../components/store/utilisateur/post-listePublicationGenerale'; //Utilisateur

//Global
import LoadingSpinner from '../components/global/Spinner';
import NotificationBubble from '../components/global/NotificationBubble';


const Accueil = () => {
  
  const [publications, setPublications] = useState([]);

  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [asLoadingMore, setAsLoadingMore] = useState(false);
  const [asMorePublication, setAsMorePublication] = useState(true);
  const [numberSkip, setNumberSkip] = useState(0);
  
  const [isLoading, setIsLoading] = useState(false); 
  const token = localStorage.getItem('token'); 


  const { 
    getListePublicationVisiteur, 
    publicationsVisiteur, 
    // loading: loadingVisiteur, 
    error: errorVisiteur 
  } = useGetListePublicationVisiteur(numberSkip);

  const { 
    getListePublicationUtilisateur, 
    publicationsUtilisateur, 
    // loading: loadingUtilisateur, 
    error: errorUtilisateur 
  } = useGetListePublicationUtilisateur(numberSkip);
  



  //Chargement initiale
  useEffect(() => {

    const fetchInitialPublications = async () => {
      
      if (token) {
        await getListePublicationUtilisateur();
      } else {
        await getListePublicationVisiteur();
      }

    };

    fetchInitialPublications();


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);





  //Gestion du système de chargement des publications supplémentaires
  useEffect(() => {

    if(isFirstLoading){

      if (token) {
        if (publicationsUtilisateur && publicationsUtilisateur.length > 0) {
          setPublications(publicationsUtilisateur);
          setIsFirstLoading(false)
        }
      } else {
        if (publicationsVisiteur && publicationsVisiteur.length > 0) {
          setPublications(publicationsVisiteur); 
          setIsFirstLoading(false)
        }
      }

    }
    else
    {

      if(asLoadingMore)
      {

        if (token) {

          if (publicationsUtilisateur && publicationsUtilisateur.length > 0) {
            setPublications((prev) => [...prev, ...publicationsUtilisateur]);
            setAsLoadingMore(false)
          }
          else
          {
            setAsMorePublication(false)//Il n'y à plus de publication à charger
            setAsLoadingMore(false)
          }
  
        }
        else {
  
          if (publicationsVisiteur && publicationsVisiteur.length > 0) {
            setPublications((prev) => [...prev, ...publicationsVisiteur]);
            setAsLoadingMore(false)
          }
          else
          {
            setAsMorePublication(false)//Il n'y à plus de publication à charger
            setAsLoadingMore(false)
          }
  
        }

      }


    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicationsVisiteur, publicationsUtilisateur]);



  //Déclenchement du chargement des publications supplémentaires
  const loadPublications = async () => {

    if (isLoading) return; // Permet d'évitre la duplication de requête
    setIsLoading(true);
    setAsLoadingMore(true)


    try {

      if (token) {
        await getListePublicationUtilisateur();
      } 
      else {
        await getListePublicationVisiteur();
      }

    } catch (error) {
      console.error('Error loading publications');
    } finally {
      setIsLoading(false); 
    }
    
    
  };


  useEffect(() => {
    
    if (numberSkip > 0) {
      
      if(asMorePublication) 
      {    
        loadPublications();
      }

    }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberSkip]); 


  useEffect(() => {

    if(asMorePublication) 
    { 

      const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop + 50 >= document.documentElement.scrollHeight) {
          setNumberSkip((prevSkip) => prevSkip + 9);
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
   
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  
  return (
    <div>
      <Helmet>

        <title>FEED - LeekWe</title>
        <meta name="description" content="Découvrez les dernières publications de nos utilisateurs sur LeekWe." />
          
      </Helmet>

      <main>
        {token ? (
          <PublicationListUtilisateur publications={publications} provenance="PAGE_CLASSIQUE"/>
        ) : (
          <PublicationListeVisiteur publications={publications} provenance="PAGE_CLASSIQUE" />
        )}

        {isLoading && 
          <LoadingSpinner />
        }

        {asLoadingMore && 
          <LoadingSpinner />
        }

        <NotificationBubble
          updateResult={""}
          errorResult={errorVisiteur || errorUtilisateur}
          successMessage = ""
          navigation = ""
        />

      
      </main>
    </div>
  );


};


export default Accueil;
