import { useState } from 'react';
import axios from 'axios';


//Contexte
import { useConfig } from '../../contexte/ConfigContext';

/*
Récupération de la liste des catégories pour une organisation
*/

const usePostApiKeyPublicStripe = (uuidPaiement) => {

  const generalConfigSysteme = useConfig();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [apiKeyPublicStripe, setApiKeyPublicStripe] = useState([]);
  const [responseMessage, setResponseMessage] = useState('');

  const postApiKeyPublicStripe = async () => {
    setLoading(true);
    setError(null);


    const data = JSON.stringify({
      uuidPaiement: uuidPaiement, 
    });

    const config = {
      method: 'post',
      url: `${generalConfigSysteme.apiUrl}/api/public/get-api-key-public-stripe`,
      headers: {
        'Content-Type': 'application/json' 
      },
      data: data
    };


    try {
      const response = await axios.request(config);
      setApiKeyPublicStripe(response.data);
      setResponseMessage('Lien vérifié avec succès.');
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { postApiKeyPublicStripe, apiKeyPublicStripe, loading, error, responseMessage };
};

export default usePostApiKeyPublicStripe;
