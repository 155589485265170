import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useParams } from 'react-router-dom';


//Store
import usePostApiKeyPublicStripe from '../../store/public/post-getApiKeyPublicStripe';



const StripeLoader = ({ children }) => {

  //Param uuidPaiement
  const { uuidPaiement } = useParams();

  const [stripeKey, setStripeKey] = useState(null);
  const [stripePromise, setStripePromise] = useState(null); 

  const {
    postApiKeyPublicStripe, 
    apiKeyPublicStripe, 
    // loadingValueCpoByPublication, 
    // errorApiKeyPublicStripe
  } = usePostApiKeyPublicStripe(uuidPaiement);



  // Fonction pour récupérer la clé publique Stripe depuis le backend
  const fetchStripeKey = async () => {
    try {

      //Récupératio de la clé api public pour le paiement
      postApiKeyPublicStripe();

    } catch (error) {
      console.error("Erreur lors de la récupération de la clé Stripe", error);
    }
  };


  useEffect(() => {

    if (uuidPaiement) {
      fetchStripeKey();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuidPaiement]);

  
  useEffect(() => {

    if (apiKeyPublicStripe) {
      setStripeKey(apiKeyPublicStripe.key);  
    }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKeyPublicStripe]);


  useEffect(() => {

    if (stripeKey) {
      const stripePromiseInstance = loadStripe(stripeKey);
      setStripePromise(stripePromiseInstance); 
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeKey]);




  return <Elements stripe={stripePromise}>{children}</Elements>;

};

export default StripeLoader;
