import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//Layout
import LikeUnlikeButtonUtilisateur from './LikeUnlikeButtonUtilisateur';
import ShareLink from '../../global/ShareLink';
import Note from '../../global/MoyenneNote';

//Global
import GestionTagLists from '../../global/GestionTagListDisplay';

//Contexte
import { useConfig } from '../../contexte/ConfigContext';


//Assets
import flecheDroite from '../../../assets/fleche-petite-droite.png';
import LogoLeekWeSimple from '../../../assets/Logo-LeekWe-simple.webp';
import flecheDirectionGauche from '../../../assets/fleche-direction-gauche.png';
import flecheDirectionDroite from '../../../assets/fleche-direction-droite.png';


const PublicationListUtilisateur = ({ publications, provenance }) => {
    
    const generalConfigSysteme = useConfig();

    const [localPublications, setLocalPublications] = useState([]);

    //Initialisation de la publication en local
    useEffect(() => {
        setLocalPublications(publications);
    }, [publications]);
    

    return (

        
        <div className={`${provenance === 'PAGE_ORGANISATION' ? 'list-service-pageOrganisation-container' : ''}`}>


            {/* Flèche gauche */}
            {provenance === 'PAGE_ORGANISATION' && (

                <button 
                    className="scroll-arrow left-arrow" 
                    onClick={() => document.querySelector('.container-publication-pageOrganisation').scrollBy({ left: -200, behavior: 'smooth' })}
                >
                    <img 
                        src={flecheDirectionGauche} 
                        alt='fleche gauche'
                        style={{ width: '20px', height: '20px', marginTop: '7px', marginBottom: '1px', marginRight: "2px"}}
                    />
                </button>

            )}


            <div className={`${provenance === 'PAGE_ORGANISATION' ? 'container-publication-pageOrganisation' : 'container'}`}>
                
                <ul className={`${provenance === 'PAGE_ORGANISATION' ? 'user-list-pageOrganisation' : 'user-list'}`}>

                    {localPublications.length > 0 ? (

                        <>  

                            {localPublications.map((publication) => (
                                <li key={publication.uuid} className="user-item">
                                    <div className="user-info">
                                        <img
                                            alt={`${publication.prenom || 'Utilisateur'} - Profil`}
                                            className="image-profil"
                                            src={publication.lien_photo_profil ? generalConfigSysteme.imageLinkBaseURL + publication.lien_photo_profil : LogoLeekWeSimple}
                                        />
                                        <div className="user-details">
                                            <h2 className="sousTitre2">{publication.prenom || 'N/A'}</h2>
                                            <h3 className="sousTitre2Gris">{publication.titre || 'N/A'}</h3>
                                        </div>
                                    </div>
                
                                    {publication.is_confidential !== 1 ? (
                                        <>
                                            <Link to={`/publication/${publication.uuid}`} className="publication-link">
                                                <img
                                                    alt={`Publication de ${publication.prenom || 'Utilisateur'} avec ${publication.titre || 'Organisation'}`}
                                                    className="image-publication"
                                                    loading="lazy"
                                                    src={publication.lien_image ? generalConfigSysteme.imageLinkBaseURL + publication.lien_image : LogoLeekWeSimple}
                                                />
                                            </Link>
                
                                            <span style={{ display: 'block', height: '20px' }}></span>
                
                                            <div className="action-container">
                                                <div className="left-actions">
                                                    <LikeUnlikeButtonUtilisateur
                                                        publication={publication}
                                                        setLocalPublications={setLocalPublications}
                                                    />
                                                    <ShareLink 
                                                        generatedShareLink={`https://www.leekwe.com/publication/${publication.uuid}`} 
                                                    />
                                                    <Link to={`/publication/${publication.uuid}`} className="buttonIconeDroite">
                                                        <Note moyenne={publication.moyenne_avis_client} />
                                                    </Link>
                                                </div>
                                                <Link to={`/publication/${publication.uuid}`} className="buttonIconeDroite">
                                                    <p className='description'>Consulter</p>
                                                    <img 
                                                        src={flecheDroite} 
                                                        alt='Flèche vers publication'
                                                        style={{ width: '24px', height: '24px', marginLeft: '5px' }}
                                                    />
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <Link to={`/publication/${publication.uuid}`} className="publication-link">
                                            <p className="descriptionConfidentiel">
                                                La photo de cette publication n'est pas disponible. Elle a été définie comme confidentielle.
                                            </p>
                                        </Link>
                                    )}
                
                                    <span style={{ display: 'block', height: '15px' }}></span>
                
                                    <Link to={`/publication/${publication.uuid}`} className="publication-link">
                                        <p className="description">
                                            {publication.prenom || 'N/A'} a bénéficié de la prestation {publication.titre_prestation || 'N/A'} fournie par {publication.titre || 'N/A'}.
                                        </p>
                                    </Link>
                
                                    <span style={{ display: 'block', height: '10px' }}></span>
                
                                    <Link to={`/publication/${publication.uuid}`} className="publication-link">
                                        <GestionTagLists tags={publication.tag_prestation || []} />
                                    </Link>
                                </li>
                            ))}

                        
                        </>
                    ) : (
                        <>
                            <p className="description">Aucune publications.</p>
                        </>
                    )}

                </ul>

            </div>

            {/* Flèche droite */}
            {provenance === 'PAGE_ORGANISATION' && (

                <button 
                    className="scroll-arrow right-arrow" 
                    onClick={() => document.querySelector('.container-publication-pageOrganisation').scrollBy({ left: 200, behavior: 'smooth' })}
                >
                    <img 
                        src={flecheDirectionDroite} 
                        alt='feche droite'
                        style={{ width: '20px', height: '20px', marginTop: '7px', marginBottom: '1px', marginLeft: "2px"}}
                    />
                </button>
                
            )}

        </div>


    );
    
};


export default PublicationListUtilisateur;
