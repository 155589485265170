import React, { useState } from 'react';
import usePostLikeUnlikePublication from '../../store/utilisateur/post-likeUnlikePublication';


//Assets
import CoeurVide from '../../../assets/icone-coeur-vide.png';
import CoeurPlein from '../../../assets/icone-coeur-complet.png';

const LikeUnlikeButtonUtilisateur = ({ publication, setLocalPublications }) => {
    const { uuid, is_liked_by_user } = publication;
    const [noHover, setNoHover] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    
    const { 
        postLikeUnlikePublication, 
        loading, 
        // error 
    } = usePostLikeUnlikePublication(uuid);


    const handleLikeUnlike = () => {

        setLocalPublications((prevPublications) =>
            prevPublications.map((pub) =>
                pub.uuid === uuid
                    ? { ...pub, is_liked_by_user: !pub.is_liked_by_user }
                    : pub
            )
        );

        postLikeUnlikePublication();

    };

    const handleClick = () => {
        setNoHover(true);
        setIsClicked(true); 
        handleLikeUnlike();

        setTimeout(() => {
            setNoHover(false);
            setIsClicked(false);  
        }, 400);
    };


    return (
        <button 
            onClick={handleClick} 
            disabled={loading} 
            className={`likeButton ${is_liked_by_user && isClicked ? 'liked' : ''} ${noHover ? 'no-hover' : ''}`}
        >
            <img 
                src={is_liked_by_user ? CoeurPlein : CoeurVide} 
                alt={is_liked_by_user ? 'Unlike' : 'Like'} 
                style={{ width: '24px', height: '24px' }}
            />
        </button>
    );
};



export default LikeUnlikeButtonUtilisateur;
