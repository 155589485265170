import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation  } from 'react-router-dom';

import { ConnexionProvider } from './components/contexte/public/ConnexionContext';
import { ConfigProvider } from './components/contexte/ConfigContext' ;
import { AuthOrganisationMenuComponent}  from './components/security/ProtectedMenuOrganisation';
import StripeLoader from './components/layouts/public/StripeLoader';

//Global
import LoadingSpinner from './components/global/Spinner';

//CSS
import './styles/Global.css'


import Accueil from './public/Accueil';

const TopNavLayout = lazy(() => import('./components/layouts/TopNavLayout'));
const AdministrationDashboard = lazy(() => import('./components/layouts/administration/AdministrationDashboard'));

const Contact = lazy(() => import('./public/Contact'));
const Login = lazy(() => import('./public/Login'));
const InscriptionRoutes = lazy(() => import('./wrapper/public/InscriptionRoutes'));
const ConfirmationMail = lazy(() => import('./public/ConfirmationMail'));
const ConfirmationProvisualMail = lazy(() => import('./public/ConfirmationProvisualMail'));
const MentionsLegales = lazy(() => import('./public/MentionsLegales'));
const PolitiqueContidentialite = lazy(() => import('./public/PolitiqueContidentialite'));
const Cgu = lazy(() => import('./public/Cgu'));
const Cgv = lazy(() => import('./public/Cgv'));
const DescriptionNewsletter = lazy(() => import('./public/DescriptionNewsletter'));


const ResetPasswordSendEmail = lazy(() => import('./public/ResetPasswordSendEmail'));
const ResetPassword = lazy(() => import('./public/ResetPassword'));
const MailEnAttenteValidation = lazy(() => import('./public/MailEnAttenteValidation'));
const PreEnregistrementRoutes = lazy(() => import('./wrapper/public/PreEnregistrementRoutes'));
const DetailsPublication = lazy(() => import('./public/DetailsPublication'));
const PageOrganisation = lazy(() => import('./public/PageOrganisation'));

const ProtectedRouteUser = lazy(() => import('./components/security/ProtectedRouteUser'));
const Dashboard = lazy(() => import('./utilisateur/Dashboard'));
const Profil = lazy(() => import('./utilisateur/accountInformation/Profil'));
const NomPrenom = lazy(() => import('./utilisateur/accountInformation/NomPrenom'));
const UpdateProfilPicture = lazy(() => import('./utilisateur/accountInformation/ProfilPicture'));
const Email = lazy(() => import('./utilisateur/accountInformation/Email'));
const AttenteConfirmationChangementMail = lazy(() => import('./utilisateur/accountInformation/AttenteConfirmationChangementMail'));
const Telephone = lazy(() => import('./utilisateur/accountInformation/Telephone'));
const InformationLegale = lazy(() => import('./utilisateur/accountInformation/InformationLegale'));
const CreateAvisPublication = lazy(() => import('./utilisateur/CreateAvisPublication'));
const UpdateAvisPublication = lazy(() => import('./utilisateur/UpdateAvisPublication'));

//Paiement
const ProceedToPayment = lazy(() => import('./utilisateur/ProceedToPayment'));
const ListPayment = lazy(() => import('./utilisateur/ListPayment'));


const ProtectedRouteOrganisation = lazy(() => import('./components/security/ProtectedRouteOrganisation'));
const listeOrganisation = lazy(() => import('./organisation/ListeOrganisation'));
const OrganisationMenu = lazy(() => import('./components/layouts/organisation/OrganisationMenu'));
const OrgDashboard = lazy(() => import('./organisation/Dashboard'));
const OrgClient = lazy(() => import('./organisation/Clients'));
const FicheClient = lazy(() => import('./organisation/FicheClient'));
const OrgPublication = lazy(() => import('./organisation/Publications'));
const CreatePaiementOrganisation = lazy(() => import('./organisation/CreatePaiementOrganisation'));
const DetailsPublicationOrganisation = lazy(() => import('./organisation/DetailsPublication'));
const CreatePublicationRoutes = lazy(() => import('./wrapper/organisation/CreatePublicationRoutes'));
const UpdatePublicationPhotoPublicationRoutes = lazy(() => import('./wrapper/organisation/UpdatePublicationPhotoPublicationRoutes'));
const GestionOrganisation = lazy(() => import('./organisation/gestionOrganisation/GestionOrganisation'));
const OrgParametrage = lazy(() => import('./organisation/Parametrage')); 
const InformationGenerale = lazy(() => import('./organisation/gestionOrganisation/InformationGenerale'));
const ProfilPictureOrganisation = lazy(() => import('./organisation/gestionOrganisation/ProfilPictureOrganisation'));
const ListeCategorie = lazy(() => import('./organisation/categorie/ListeCategorie'));
const CreateCategorie = lazy(() => import('./organisation/categorie/CreateCategorie'));
const UpdateCategorie = lazy(() => import('./organisation/categorie/UpdateCategorie'));
const ListeCpo = lazy(() => import('./organisation/cpo/ListeCpo'));
const CreateCpoPublication = lazy(() => import('./organisation/cpo/CreateCpoPublication'));
const UpdateCpoPublication = lazy(() => import('./organisation/cpo/UpdateCpoPublication'));
const ListePrestation = lazy(() => import('./organisation/prestation/ListePrestation'));
const CreatePrestation = lazy(() => import('./organisation/prestation/CreatePrestation'));
const UpdatePrestation = lazy(() => import('./organisation/prestation/UpdatePrestation'));
const ListeTagPrestation = lazy(() => import('./organisation/tagsPrestation/ListeTagPrestation'));
const CreateTagPrestation = lazy(() => import('./organisation/tagsPrestation/CreateTagPrestation'));
const UpdateTagPrestation = lazy(() => import('./organisation/tagsPrestation/UpdateTagPrestation'));
const ListeGestionnaire = lazy(() => import('./organisation/gestionnaires/ListeGestionnaire'));
const CreateGestionnaire = lazy(() => import('./organisation/gestionnaires/CreateGestionnaire'));
const UpdateGestionnaire = lazy(() => import('./organisation/gestionnaires/UpdateGestionnaire'));




const ProtectedRouteAdministrateur = lazy(() => import('./components/security/ProtectedRouteAdministrateur'));
const AdminDashboard = lazy(() => import('./administration/AdminDashboard'));

const RestrictedArea = lazy(() => import('./public/RestrictedArea'));
const NotFound = lazy(() => import('./public/NotFound'));
const Experience = lazy(() => import('./utilisateur/experience/Experience'));


// Composant pour envoyer des événements de page vue à Google Analytics
const GoogleAnalyticsHandler = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-2JRMDXGBWW', {
        page_path: location.pathname,
        page_title: document.title,
      });
    }
  }, [location]);

  return null;
};


function App() {
  // Charger Google Analytics une seule fois lors du montage de l'application
  useEffect(() => {

    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-2JRMDXGBWW';
    script.async = true;
    document.body.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-2JRMDXGBWW');

    console.log('Google Analytics initialized');

    return () => {
      document.body.removeChild(script);
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //On accède ici à uuid paiement inscrit dans l'url
  // const stripePromise = loadStripe('pk_test_51QEA2eBN8AUXM0jStiSVpMybarAqtaAOomrGrnPfNfzZWVcTY9DzgDCjSYRo6YXGDqL7VPJKaCG0f0jxUZdHd5GM0051K9oxp3');
  // const currentUrl = window.location.href;
  // const uuidPaiement = currentUrl.split('/paiement/')[1];
  

  return (
    <ConfigProvider>
      <ConnexionProvider>

        <Router>
          
          <GoogleAnalyticsHandler />

          <Suspense fallback={<LoadingSpinner />}>

            <div>

              <Routes>


                {/* Routes publiques ************************************************************************************************************************/}
                <Route 
                  path="/" 
                  element={
                    <TopNavLayout> 
                      <Accueil /> 
                    </TopNavLayout>
                  } 
                />

                <Route 
                  path="/contact" 
                  element={
                    <TopNavLayout> 
                      <Contact /> 
                    </TopNavLayout>
                  } 
                />

                <Route 
                  path="/login" 
                  element={
                    <Login /> 
                  } 
                />

                <Route 
                  path="/inscription/*" 
                  element={
                    <InscriptionRoutes /> 
                  } 
                />

                <Route 
                  path="/confirmation-mail/:provisionalUuidMail" 
                  element={
                    <ConfirmationMail /> 
                  } 
                />

                <Route
                  path="/confirmation-changement-mail/:provisialUuidMailChangeConfirmation"
                  element={
                    <ConfirmationProvisualMail /> 
                  }
                />

                <Route 
                  path="/mail-en-attente" 
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                    <TopNavLayout> 
                      <MailEnAttenteValidation /> 
                    </TopNavLayout>
                    </Suspense>
                  } 
                />

                <Route 
                  path="/send-email-reset-password" 
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      {/* <TopNavLayout>  */}
                        <ResetPasswordSendEmail /> 
                      {/* </TopNavLayout> */}
                    </Suspense>
                  } 
                />

                <Route 
                  path="/reset-password/:uuidProvisionnalPasswordReset" 
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      {/* <TopNavLayout>  */}
                        <ResetPassword /> 
                      {/* </TopNavLayout> */}
                    </Suspense>
                  } 
                />

                <Route 
                  path="/pre-enregistrement/:uuidPublication/*" 
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <PreEnregistrementRoutes /> 
                    </Suspense>
                  } 
                />

                <Route 
                  path="/publication/:uuidPublication" 
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <TopNavLayout> 
                        <DetailsPublication /> 
                      </TopNavLayout> 
                    </Suspense>
                  } 
                />

                  

                <Route 
                  path="/:nomOrganisation/:uuidOrganisation" 
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <TopNavLayout> 
                        <PageOrganisation /> 
                      </TopNavLayout> 
                    </Suspense>
                  } 
                />


                <Route 
                  path="/mentions-legales" 
                  element={
                    <TopNavLayout>
                      <MentionsLegales /> 
                    </TopNavLayout>
                  } 
                />

                <Route 
                  path="/politique-de-confidentialite" 
                  element={
                    <TopNavLayout>
                      <PolitiqueContidentialite /> 
                    </TopNavLayout>
                  } 
                />

                <Route 
                  path="/cgu" 
                  element={
                    <TopNavLayout>
                      <Cgu /> 
                    </TopNavLayout>
                  } 
                />

                <Route 
                  path="/cgv" 
                  element={
                    <TopNavLayout>
                      <Cgv /> 
                    </TopNavLayout>
                  } 
                />


                <Route 
                  path="/desinscription-newsletter/:email/:uuid" 
                  element={
                    <TopNavLayout>
                      <DescriptionNewsletter /> 
                    </TopNavLayout>
                  } 
                />

                


                {/* Routes utilisateurs ************************************************************************************************************************/}
                <Route
                  path="/dashboard"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <TopNavLayout> 
                        <ProtectedRouteUser 
                          element={Dashboard} 
                          requiredRoleUser={[1, 2]}
                        />
                      </TopNavLayout> 
                    </Suspense>
                  }
                />
                
                <Route
                  path="/dashboard/profil"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <TopNavLayout> 
                        <ProtectedRouteUser 
                          element={Profil} 
                          requiredRoleUser={[1, 2]}
                        />
                      </TopNavLayout> 
                    </Suspense>
                  }
                />
                
                <Route
                  path="/dashboard/profil/update-nom-prenom"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <TopNavLayout>
                        <ProtectedRouteUser 
                          element={NomPrenom} 
                          requiredRoleUser={[1, 2]}
                        />
                      </TopNavLayout>
                    </Suspense>
                  }
                />

                <Route
                  path="/dashboard/profil/update-profil-picture"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <TopNavLayout>
                        <ProtectedRouteUser 
                          element={UpdateProfilPicture} 
                          requiredRoleUser={[1, 2]}
                        />
                      </TopNavLayout>
                    </Suspense>
                  }
                />


                <Route
                  path="/dashboard/profil/update-email"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <TopNavLayout>
                        <ProtectedRouteUser 
                          element={Email} 
                          requiredRoleUser={[1, 2]}
                        />
                      </TopNavLayout>
                    </Suspense>
                  }
                />

                <Route
                  path="/dashboard/profil/attente-confirmation-mail"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <TopNavLayout>
                        <ProtectedRouteUser 
                          element={AttenteConfirmationChangementMail} 
                          requiredRoleUser={[1, 2]}
                        />
                      </TopNavLayout>
                    </Suspense>
                  }
                />

                <Route
                  path="/dashboard/profil/update-telephone"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <TopNavLayout>
                        <ProtectedRouteUser 
                          element={Telephone} 
                          requiredRoleUser={[1, 2]}
                        />
                      </TopNavLayout>
                    </Suspense>
                  }
                />

                <Route
                  path="/dashboard/profil/informations-légales"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <TopNavLayout>
                        <ProtectedRouteUser 
                          element={InformationLegale} 
                          requiredRoleUser={[1, 2]}
                        />
                      </TopNavLayout>
                    </Suspense>
                  }
                />

                <Route
                  path="/dashboard/experiences"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <ProtectedRouteUser 
                        element={Experience} 
                        requiredRoleUser={[1, 2]}
                      />
                    </Suspense>
                  }
                />

                <Route
                  path="/publication/:uuidPublication/avis"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <TopNavLayout>
                        <ProtectedRouteUser 
                          element={CreateAvisPublication} 
                          requiredRoleUser={[1, 2]}
                        />
                      </TopNavLayout>
                    </Suspense>
                  }
                />

                <Route
                  path="/publication/:uuidPublication/update-avis"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <TopNavLayout>
                        <ProtectedRouteUser 
                          element={UpdateAvisPublication} 
                          requiredRoleUser={[1, 2]}
                        />
                      </TopNavLayout>
                    </Suspense>
                  }
                />

              
                <Route
                  path="/liste-paiement"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <TopNavLayout>
                        <ProtectedRouteUser 
                          element={ListPayment} 
                          requiredRoleUser={[1, 2]}
                        />
                      </TopNavLayout>
                    </Suspense>
                  }
                />


                <Route
                  path="/paiement/:uuidPaiement"
                  element={
                    <StripeLoader>
                      <Suspense fallback={<LoadingSpinner />}>
                        <TopNavLayout>
                          <ProtectedRouteUser 
                            element={ProceedToPayment} 
                            requiredRoleUser={[1, 2]}
                          />
                        </TopNavLayout>
                      </Suspense>
                    </StripeLoader>
                  }
                />




                {/* Routes Administration ************************************************************************************************************************/}
                <Route
                  path="/administration/dashboard"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <ProtectedRouteAdministrateur
                        element={() => (
                          <AdministrationDashboard>
                            <AdminDashboard />
                          </AdministrationDashboard>
                        )}
                        requiredRoleUser={[1]}
                      />
                    </Suspense>
                  }
                />









                {/* Routes organisations ************************************************************************************************************************/}
                <Route
                  path="/organisation/liste-organisations"
                  element={
                    <ProtectedRouteOrganisation 
                      element={listeOrganisation} 
                      requiredAutorisation={[]} 
                    />
                  }
                />

                <Route
                  path="/organisation/:idOrganisation/dashboard"
                  element={
                    <AuthOrganisationMenuComponent>
                      <ProtectedRouteOrganisation
                        element={() => (
                          <OrganisationMenu>
                            <OrgDashboard />
                          </OrganisationMenu>
                        )}
                        requiredAutorisation={[1, 2]}
                      />
                    </AuthOrganisationMenuComponent>
                  }
                />

                <Route
                  path="/organisation/:idOrganisation/clients"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <ProtectedRouteOrganisation
                            element={() => (
                              <OrganisationMenu>
                                <OrgClient />
                              </OrganisationMenu>
                            )}
                            requiredAutorisation={[1]}
                          />
                      </AuthOrganisationMenuComponent>
                    </Suspense>
                  }
                />

                <Route
                  path="/organisation/:idOrganisation/clients/fiche-client/:uuidUtilisateur"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <ProtectedRouteOrganisation
                            element={() => (
                              <OrganisationMenu>
                                <FicheClient />
                              </OrganisationMenu>
                            )}
                            requiredAutorisation={[1]}
                          />
                      </AuthOrganisationMenuComponent>
                    </Suspense>
                  }
                />
                
                <Route
                  path="/organisation/:idOrganisation/publications"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                        <ProtectedRouteOrganisation
                          element={() => (
                            <OrganisationMenu>
                              <OrgPublication />
                            </OrganisationMenu>
                          )}
                          requiredAutorisation={[1,2]}
                        />
                      </AuthOrganisationMenuComponent>
                    </Suspense>
                  }
                />

                <Route
                  path="/organisation/:idOrganisation/publications/:uuidPublication"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <ProtectedRouteOrganisation
                            element={() => (
                              <OrganisationMenu>
                                <DetailsPublicationOrganisation />
                              </OrganisationMenu>
                            )}
                            requiredAutorisation={[1,2]}
                          />
                      </AuthOrganisationMenuComponent>
                    </Suspense>
                  }
                />


                <Route
                  path="/organisation/:idOrganisation/publications/:uuidPublication/create-paiement"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <ProtectedRouteOrganisation
                            element={() => (
                              <OrganisationMenu>
                                <CreatePaiementOrganisation />
                              </OrganisationMenu>
                            )}
                            requiredAutorisation={[1,2]}
                          />
                      </AuthOrganisationMenuComponent>
                    </Suspense>
                  }
                />

                <Route
                  path="/organisation/:idOrganisation/create-publication/*"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <OrganisationMenu>
                            <CreatePublicationRoutes />
                          </OrganisationMenu>
                      </AuthOrganisationMenuComponent>
                    </Suspense>
                  }
                />

                <Route
                  path="/organisation/:idOrganisation/update-photo-publication/:uuidPublication/*"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <OrganisationMenu>
                            <UpdatePublicationPhotoPublicationRoutes />
                          </OrganisationMenu>
                      </AuthOrganisationMenuComponent>
                    </Suspense>
                  }
                />



                <Route
                  path="/organisation/:idOrganisation/parametrage"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <ProtectedRouteOrganisation
                            element={() => (
                              <OrganisationMenu>
                                <OrgParametrage />
                              </OrganisationMenu>
                            )}
                            requiredAutorisation={[1]}
                          />
                      </AuthOrganisationMenuComponent>
                    </Suspense>
                  }
                />

                <Route
                  path="/organisation/:idOrganisation/parametrage/gestion-organisation"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <ProtectedRouteOrganisation
                            element={() => (
                              <OrganisationMenu>
                                <GestionOrganisation />
                              </OrganisationMenu>
                            )}
                            requiredAutorisation={[1]}
                          />
                      </AuthOrganisationMenuComponent>
                    </Suspense>
                  }
                />
                
                <Route
                  path="/organisation/:idOrganisation/parametrage/update-general-information"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <ProtectedRouteOrganisation
                            element={() => (
                              <OrganisationMenu>
                                <InformationGenerale />
                              </OrganisationMenu>
                            )}
                            requiredAutorisation={[1]}
                          />
                      </AuthOrganisationMenuComponent>
                    </Suspense>
                  }
                />

                <Route
                  path="/organisation/:idOrganisation/parametrage/update-profil-picture"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <ProtectedRouteOrganisation
                            element={() => (
                              <OrganisationMenu>
                                <ProfilPictureOrganisation />
                              </OrganisationMenu>
                            )}
                            requiredAutorisation={[1]}
                          />
                      </AuthOrganisationMenuComponent>
                    </Suspense>
                  }
                />

                

                <Route
                  path="/organisation/:idOrganisation/parametrage/categories"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <ProtectedRouteOrganisation
                            element={() => (
                              <OrganisationMenu>
                                <ListeCategorie />
                              </OrganisationMenu>
                            )}
                            requiredAutorisation={[1]}
                          /> 
                      </AuthOrganisationMenuComponent>
                    </Suspense>
                  }
                />

                <Route
                  path="/organisation/:idOrganisation/parametrage/create-categorie"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <ProtectedRouteOrganisation
                            element={() => (
                              <OrganisationMenu>
                                <CreateCategorie />
                              </OrganisationMenu>
                            )}
                            requiredAutorisation={[1]}
                          />
                      </AuthOrganisationMenuComponent>
                    </Suspense>

                  }
                />

                <Route
                  path="/organisation/:idOrganisation/parametrage/update-categorie/:uuidCategorie"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <ProtectedRouteOrganisation
                            element={() => (
                              <OrganisationMenu>
                                <UpdateCategorie />
                              </OrganisationMenu>
                            )}
                            requiredAutorisation={[1]}
                          />
                      </AuthOrganisationMenuComponent>
                    </Suspense>
                  }
                />

                <Route
                  path="/organisation/:idOrganisation/parametrage/cpo"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <ProtectedRouteOrganisation
                            element={() => (
                              <OrganisationMenu>
                                <ListeCpo />
                              </OrganisationMenu>
                            )}
                            requiredAutorisation={[1]}
                          />
                      </AuthOrganisationMenuComponent>
                    </Suspense>
                  }
                />

                <Route
                  path="/organisation/:idOrganisation/parametrage/create-cpo-publication"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <ProtectedRouteOrganisation
                            element={() => (
                              <OrganisationMenu>
                                <CreateCpoPublication />
                              </OrganisationMenu>
                            )}
                            requiredAutorisation={[1]}
                          />
                      </AuthOrganisationMenuComponent>
                    </Suspense>
                  }
                />
                

                <Route
                  path="/organisation/:idOrganisation/parametrage/update-cpo-publication/:uuidCpoPublication"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <ProtectedRouteOrganisation
                            element={() => (
                              <OrganisationMenu>
                                <UpdateCpoPublication />
                              </OrganisationMenu>
                            )}
                            requiredAutorisation={[1]}
                          />
                      </AuthOrganisationMenuComponent>
                    </Suspense>
                  }
                />


                <Route
                  path="/organisation/:idOrganisation/parametrage/prestations"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <ProtectedRouteOrganisation
                            element={() => (
                              <OrganisationMenu>
                                <ListePrestation />
                              </OrganisationMenu>
                            )}
                            requiredAutorisation={[1]}
                          />
                      </AuthOrganisationMenuComponent>
                    </Suspense>
                  }
                />

                <Route
                  path="/organisation/:idOrganisation/parametrage/create-prestation"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <ProtectedRouteOrganisation
                            element={() => (
                              <OrganisationMenu>
                                <CreatePrestation />
                              </OrganisationMenu>
                            )}
                            requiredAutorisation={[1]}
                          />
                      </AuthOrganisationMenuComponent>
                    </Suspense>
                  }
                />

                <Route
                  path="/organisation/:idOrganisation/parametrage/update-prestation/:uuidPrestation"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <ProtectedRouteOrganisation
                            element={() => (
                              <OrganisationMenu>
                                <UpdatePrestation />
                              </OrganisationMenu>
                            )}
                            requiredAutorisation={[1]}
                          />
                      </AuthOrganisationMenuComponent>
                    </Suspense>
                  }
                />

                <Route
                  path="/organisation/:idOrganisation/parametrage/tags-prestations"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <ProtectedRouteOrganisation
                            element={() => (
                              <OrganisationMenu>
                                <ListeTagPrestation />
                              </OrganisationMenu>
                            )}
                            requiredAutorisation={[1]}
                          />
                      </AuthOrganisationMenuComponent>
                    </Suspense>
                  }
                />

                <Route
                  path="/organisation/:idOrganisation/parametrage/create-tag"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <ProtectedRouteOrganisation
                            element={() => (
                              <OrganisationMenu>
                                <CreateTagPrestation />
                              </OrganisationMenu>
                            )}
                            requiredAutorisation={[1]}
                          />
                      </AuthOrganisationMenuComponent>
                    </Suspense>
                  }
                />

                
                <Route
                  path="/organisation/:idOrganisation/parametrage/update-tag/:uuidTagPrestation"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <ProtectedRouteOrganisation
                            element={() => (
                              <OrganisationMenu>
                                <UpdateTagPrestation />
                              </OrganisationMenu>
                            )}
                            requiredAutorisation={[1]}
                          />
                      </AuthOrganisationMenuComponent>
                    </Suspense>  
                  }
                />


                <Route
                  path="/organisation/:idOrganisation/parametrage/gestionnaires"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <ProtectedRouteOrganisation
                            element={() => (
                              <OrganisationMenu>
                                <ListeGestionnaire />
                              </OrganisationMenu>
                            )}
                            requiredAutorisation={[1]}
                          />
                      </AuthOrganisationMenuComponent>
                    </Suspense>
                  }
                />

                <Route
                  path="/organisation/:idOrganisation/parametrage/create-gestionnaire"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <ProtectedRouteOrganisation
                            element={() => (
                              <OrganisationMenu>
                                <CreateGestionnaire />
                              </OrganisationMenu>
                            )}
                            requiredAutorisation={[1]}
                          />
                      </AuthOrganisationMenuComponent>
                    </Suspense>

                  }
                />

                <Route
                  path="/organisation/:idOrganisation/parametrage/update-gestionnaire/:uuidAutorisation"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AuthOrganisationMenuComponent>
                          <ProtectedRouteOrganisation
                            element={() => (
                              <OrganisationMenu>
                                <UpdateGestionnaire />
                              </OrganisationMenu>
                            )}
                            requiredAutorisation={[1]}
                          />
                        
                      </AuthOrganisationMenuComponent>
                    </Suspense>
                  }
                />

                <Route 
                  path="/restricted-area" 
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <TopNavLayout> 
                        <RestrictedArea /> 
                      </TopNavLayout>
                    </Suspense>
                  } 
                />


                <Route 
                  path="/404" 
                  element={
                    <TopNavLayout> 
                      <NotFound /> 
                    </TopNavLayout>
                  } 
                />

                {/*Page d'erreur 404*/}
                <Route path="*" 
                  element={
                    <TopNavLayout> 
                      <NotFound />
                    </TopNavLayout>
                  } 
                />




              </Routes>

            </div>

          </Suspense>

        </Router>
      
    
        </ConnexionProvider>
    </ConfigProvider>
    
  );
}


export default App;
