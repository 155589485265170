import React, { useState, useEffect, useCallback, useContext, createContext } from 'react';
import axios from 'axios';
import { useAuth } from '../contexte/public/ConnexionContext';

//Contexte
import { useConfig } from '../contexte/ConfigContext';

const AuthContextMenu = createContext(null);  

// Fonction pour décoder le token JWT
const decodeToken = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const decoded = atob(base64);
    return JSON.parse(decoded);
  } catch (e) {
    console.error('Erreur de décodage du jeton', e);
    return null;
  }
};

// Vérifier l'intégrité du token avec le serveur
const verifyTokenIntegrity = async (token, generalConfigSysteme) => {
  if (!token) return false;

  try {
    const response = await axios.get(`${generalConfigSysteme.apiUrl}/api/security/checkToken`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.status === 200;
  } catch (error) {
    console.error('Erreur de validation du jeton', error);
    return false;
  }
};

// Composant AuthOrganisationMenuComponent fournissant le contexte
export const AuthOrganisationMenuComponent = ({ children }) => {
  
  const generalConfigSysteme = useConfig();
  const { token } = useAuth();
  const [isTokenValid, setIsTokenValid] = useState(null);
  const [decodedToken, setDecodedToken] = useState(null);

  const checkToken = useCallback(async () => {
    if (token) {
      const isValid = await verifyTokenIntegrity(token, generalConfigSysteme);
      setIsTokenValid(isValid);
      if (isValid) {
        const decoded = decodeToken(token);
        setDecodedToken(decoded);
      }
    } else {
      setIsTokenValid(false);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  // Fonction pour vérifier si l'utilisateur est authentifié
  const isAuthenticated = () => {
    if (token && isTokenValid) {
      const decoded = decodeToken(token);
      return decoded && decoded.exp > Date.now() / 1000;
    }
    return false;
  };

  // Vérifier si l'utilisateur a le rôle requis pour une organisation
  const hasOrganisationRequiredRole = (idOrganisation, requiredAutorisation) => {
    if (!decodedToken) {
      return false;
    }

    if (requiredAutorisation.length === 0) {
      const countAutorisations =
        decodedToken.autorisationsOrganisations && Array.isArray(decodedToken.autorisationsOrganisations)
          ? decodedToken.autorisationsOrganisations.filter(item => item.autorisation_organisation > 0).length
          : 0;
      return countAutorisations > 0;
    }

    if (!idOrganisation) return false;

    const autorisation = Array.isArray(decodedToken.autorisationsOrganisations)
      ? decodedToken.autorisationsOrganisations.find(
          (org) => org.uuid_organisation === idOrganisation
        )
      : undefined;

    const hasRole = autorisation && requiredAutorisation.includes(autorisation.autorisation_organisation);

    return hasRole;
  };

  return (
    <AuthContextMenu.Provider value={{ isAuthenticated, hasOrganisationRequiredRole }}>
      {children}
    </AuthContextMenu.Provider>
  );
};

// Hook pour utiliser le AuthContext
export const useAuthContext = () => {
  const context = useContext(AuthContextMenu);
  if (!context) {
    console.error('useAuthContext doit être utilisé à l\'intérieur d\'un AuthOrganisationMenuComponent');
  }
  return context;
};
