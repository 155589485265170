import { useState } from 'react';
import axios from 'axios';


//Contexte
import { useConfig } from '../../contexte/ConfigContext';



/*
Récupération de la liste des publications pour un visiteur
*/

const useGetListePublicationVisiteur = (numberSkip) => {
  const generalConfigSysteme = useConfig();
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [publicationsVisiteur, setPublicationsVisiteur] = useState([]);
  const [responseMessage, setResponseMessage] = useState('');

  const getListePublicationVisiteur = async () => {
    setLoading(true);
    setError(null);

    const data = JSON.stringify({
      numberSkip: String(numberSkip)
    });

    const config = {
      method: 'post',
      url: `${generalConfigSysteme.apiUrl}/api/public/listePublication`,
      headers: {
        'Content-Type': 'application/json' 
      },
      data: data
    };


    try {
      const response = await axios.request(config);
      setPublicationsVisiteur(response.data);
      setResponseMessage('Publications récupérés avec succès.');
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { getListePublicationVisiteur, publicationsVisiteur, loading, error, responseMessage };
};

export default useGetListePublicationVisiteur;
